import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import bannerFonbet from "../img/fonbet.gif";

import TextBlock from "../components/TextBlock/TextBlock";
import Products from "../components/Products/Products";

import Layout from "../components/Layout/Layout";
import News from "../components/News/News";

import styles from "./styles/index-page.module.scss";

export const EnglishPageTemplate = ({
  image,
  about,
  platform,
  partners,
  intro,
  responsibility,
  // sponsors,
}) => {
  return (
    <>
      <div
        id="home"
        className={styles.image}
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
        }}
      />
      <div className={styles.banner}>
        <a href="http://fonbet.gr/?partner=plumo.com.cy" target="_blank">
          <img src={bannerFonbet} alt="fonbet" />
        </a>
      </div>

      <section className={styles.wrapper}>
        <TextBlock content={about} />
        <TextBlock content={platform} />
        <Products title={intro.heading} gridItems={intro.blurbs} />

        <TextBlock content={partners} />

        <div className={styles.news} id="news">
          <h2 className={styles.newsTitle}>News</h2>
          <News title={intro.heading} />
        </div>

        <TextBlock content={responsibility} />
        {/* <TextBlock content={sponsors} /> */}
      </section>
    </>
  );
};

EnglishPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const EnglishPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <EnglishPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        about={frontmatter.about}
        platform={frontmatter.platform}
        partners={frontmatter.partners}
        responsibility={frontmatter.responsibility}
        // sponsors={frontmatter.sponsors}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

EnglishPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default EnglishPage;

export const pageQuery = graphql`
  query EnglishPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "en" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        about {
          title
          id
          introText
          description
        }
        platform {
          title
          id
          introText
          description
        }
        partners {
          title
          id
          description
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        intro {
          id
          heading
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
          }
        }
        responsibility {
          title
          id
          introText
          description
        }
      }
    }
  }
`;
